import React from 'react';
import PageHeader from '../../../Components/Header/PageHeader';
import { Card, Result } from 'antd';
import useAuth from '../../../hooks/useAuth';
import { SmileOutlined } from '@ant-design/icons';
import WhatsAPPbutton from '../../../Components/Button/whatsAppButton';

export default function Integracion() {
  const { clientInfo } = useAuth();
  const Message = `¡Hola Jennifer!, porfa cuéntame más acerca de las integraciones que puede hacer tu equipo.`;

  return (
    <Card>
      <PageHeader title="Integracion" head="Nua Talker Integraciones">
        <p>
          Bienvenido a Nua Talker Integraciones, tu aliado tecnológico para
          optimizar la integración entre plataformas y desarrollar soluciones
          personalizadas. ¿Tienes múltiples plataformas que utilizas para tu
          negocio y deseas que trabajen juntas de manera fluida con HubSpot?
          Nosotros estamos aquí para hacerlo posible.
        </p>
      </PageHeader>

      {clientInfo.products.includes('NTI') ? (
        <Result
          icon={<SmileOutlined />}
          title="Estamos trabajando arduamente para entregarte un gran producto. Pronto podrás administrar tu servicio aquí."
          // extra={<Button type="primary">Next</Button>}
        />
      ) : (
        <Result
          icon={<SmileOutlined color="grenn" />}
          title="¡Hola! Parece que aún no tienes acceso a este servicio. Si deseas adquirirlo y disfrutar de todas sus ventajas, no dudes en ponerte en contacto con uno de nuestros asesores. "
          extra={<WhatsAPPbutton Message={Message} />}
        />
      )}
    </Card>
  );
}
