/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateAgents = /* GraphQL */ `
  subscription OnCreateAgents($filter: ModelSubscriptionAgentsFilterInput) {
    onCreateAgents(filter: $filter) {
      callAnswerAt
      callEndAt
      callWith
      createdAt
      displayName
      email
      hubSpotId
      inCall
      lastUpdate
      name
      queue_extension
      session
      status
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAgents = /* GraphQL */ `
  subscription OnDeleteAgents($filter: ModelSubscriptionAgentsFilterInput) {
    onDeleteAgents(filter: $filter) {
      callAnswerAt
      callEndAt
      callWith
      createdAt
      displayName
      email
      hubSpotId
      inCall
      lastUpdate
      name
      queue_extension
      session
      status
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAgents = /* GraphQL */ `
  subscription OnUpdateAgents($filter: ModelSubscriptionAgentsFilterInput) {
    onUpdateAgents(filter: $filter) {
      callAnswerAt
      callEndAt
      callWith
      createdAt
      displayName
      email
      hubSpotId
      inCall
      lastUpdate
      name
      queue_extension
      session
      status
      updatedAt
      __typename
    }
  }
`;
