import React, { useState } from 'react';
import RoundedButton from '../../../Components/Button/RoundedButton';
import { Popconfirm, message, Badge } from 'antd';
import useApi from '../../../hooks/useApi';

export default function PurgeQueue({ label, hubSpotId, queue }) {
  const { callApi } = useApi();
  const [intervals, setIntervals] = useState({});
  const [secondsLeft, setSecondsLeft] = useState({});
  const [disabledButtons, setDisabledButtons] = useState({});

  async function purgeQueue(queueId) {
    if (hubSpotId) {
      const path = `/dialer/v1/queue/purge`;
      const myAPI = 'dialer';
      const body = {
        hubSpotId,
        queueId,
      };
      const hide = message.loading(`Purgando la cola ${queueId}...`, 0);
      try {
        await callApi({
          apiName: myAPI,
          path,
          method: 'POST',
          headers: {},
          body,
        });
        hide();
        message.success(`¡Cola ${queueId} purgada!`);
        handleButtonClick(queueId);
      } catch (error) {
        hide();
        message.error(`No se pudo purgar la cola ${queueId}`);
      }
    }
  }

  const handleButtonClick = (id) => {
    setDisabledButtons((prevState) => ({
      ...prevState,
      [id]: true,
    }));
    setSecondsLeft((prevState) => ({
      ...prevState,
      [id]: 60,
    }));
    if (intervals[id]) {
      clearInterval(intervals[id]);
    }
    const intervalId = setInterval(() => {
      setSecondsLeft((prevState) => ({
        ...prevState,
        [id]: prevState[id] - 1,
      }));
    }, 1000);
    setIntervals((prevState) => ({
      ...prevState,
      [id]: intervalId,
    }));
    setTimeout(() => {
      setDisabledButtons((prevState) => ({
        ...prevState,
        [id]: false,
      }));
      clearInterval(intervalId);
    }, 60000);
  };

  return (
    <Popconfirm
      title={`¿Purgar la cola ${label}?`}
      description={
        <div>
          <p>
            Al hacerlo todas los números pendientes por llamar se eliminarán.
            <br />
            <b>
              Deberás esperar 60 segundos antes de volver a limpiar la cola.
            </b>
          </p>
        </div>
      }
      disabled={disabledButtons[queue]}
      onConfirm={() => {
        purgeQueue(queue);
      }}
      okText="Si"
      cancelText="No"
    >
      <Badge count={disabledButtons[queue] ? secondsLeft[queue] : 0}>
        <RoundedButton
          key={queue}
          onClick={undefined}
          iconName="FaQuidditch"
          disabled={disabledButtons[queue]}
        />
      </Badge>
    </Popconfirm>
  );
}
