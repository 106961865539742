/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAgents = /* GraphQL */ `
  query GetAgents($hubSpotId: String!, $queue_extension: String!) {
    getAgents(hubSpotId: $hubSpotId, queue_extension: $queue_extension) {
      callAnswerAt
      callEndAt
      callWith
      createdAt
      displayName
      email
      hubSpotId
      inCall
      lastUpdate
      name
      queue_extension
      session
      status
      updatedAt
      __typename
    }
  }
`;
export const listAgents = /* GraphQL */ `
  query ListAgents(
    $filter: ModelAgentsFilterInput
    $hubSpotId: String
    $limit: Int
    $nextToken: String
    $queue_extension: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
  ) {
    listAgents(
      filter: $filter
      hubSpotId: $hubSpotId
      limit: $limit
      nextToken: $nextToken
      queue_extension: $queue_extension
      sortDirection: $sortDirection
    ) {
      items {
        callAnswerAt
        callEndAt
        callWith
        createdAt
        displayName
        email
        hubSpotId
        inCall
        lastUpdate
        name
        queue_extension
        session
        status
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
