import React, { useState } from 'react';
import { message, Button, Form, Input, Divider } from 'antd';
import LogoApp from '../../Components/Logo';
import useAuth from '../../hooks/useAuth';
import { Link, useNavigate } from 'react-router-dom';

function Login() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { loginUser } = useAuth();

  async function signIn(values) {
    try {
      setLoading(true);
      await loginUser(values);
      message.success('¡Bienvenido de nuevo!');
      navigate('/');
    } catch (err) {
      message.error('Ha ocurrido un error');
      console.error({ err });
    } finally {
      setLoading(false);
    }
  }
  return (
    <React.Fragment>
      <div
        // @ts-ignore
        align="middle"
      >
        <LogoApp width={'20%'} color="black" />
        <p>Bienvenido de nuevo</p>
        <h1 style={{ marginTop: '-16px' }}>Inicio de sesión</h1>
      </div>
      <Form
        name="basic"
        layout="vertical"
        initialValues={{
          hubSpotId: '',
          email: '',
          password: '',
        }}
        onFinish={signIn}
      >
        <Form.Item
          label="Correo"
          name="username"
          rules={[
            {
              required: true,
              message: 'Por favor ingresa tu correo',
            },
          ]}
        >
          <Input size="large" type="email" placeholder="john.doe@mail.com" />
        </Form.Item>
        <Form.Item
          label="Contraseña"
          name="password"
          rules={[
            {
              required: true,
              message: 'Por favor ingresa tu contraseña',
            },
          ]}
        >
          <Input.Password size="large" placeholder="******" />
        </Form.Item>
        <div className="form-footer">
          ¿Olvidaste tu contraseña?{' '}
          <Link to="/auth/resetpassword">recupérala aquí</Link>
        </div>
        <Divider />
        <Form.Item>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            block
            size="large"
          >
            Iniciar sesión
          </Button>
        </Form.Item>
      </Form>
      <div className="form-footer">
        ¿Aún no tienes una cuenta?{' '}
        <Link to="/auth/register">registrate aquí</Link>
      </div>
    </React.Fragment>
  );
}

export default Login;
