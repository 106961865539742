import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { Row, Image, Col } from 'antd';
import logoBackground from '../../Images/wallpaper_for_a_web_application.jpg';

export default function PublicLayout() {
  const { user } = useAuth();

  if (user) return <Navigate to="/home" replace />;
  return (
    <div className="bg-[#F0F2F5] min-h-screen flex items-center">
      <div className="w-screen px-5">
        <Row>
          <Col span={16}>
            <Image
              src={logoBackground}
              preview={false}
              width={'100%'}
              height={'100vh'}
            />
          </Col>
          <Col span={8}>
            <div className="form-body" style={{ padding: '2rem' }}>
              <Outlet />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
