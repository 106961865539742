/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "dialer",
            "endpoint": "https://p3sc37d9va.execute-api.us-east-1.amazonaws.com/main",
            "region": "us-east-1"
        },
        {
            "name": "nuatalker",
            "endpoint": "https://z4ylopp0ei.execute-api.us-east-1.amazonaws.com/main",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://hrckgdz57vcwfnuy7ifslzct24.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-vgx3dsutlfevvbtitcfwexjzuq",
    "aws_cognito_identity_pool_id": "us-east-1:172b7cff-7104-4d57-ac59-f2f9efcd0c52",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_oEwMhBfzU",
    "aws_user_pools_web_client_id": "7vjoofk3b3jbl6ph0molm2tf8u",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "Billing-main",
            "region": "us-east-1"
        },
        {
            "tableName": "Channels-main",
            "region": "us-east-1"
        },
        {
            "tableName": "Companies-main",
            "region": "us-east-1"
        },
        {
            "tableName": "CountryCodes-main",
            "region": "us-east-1"
        },
        {
            "tableName": "Credentials-main",
            "region": "us-east-1"
        },
        {
            "tableName": "Dialer-main",
            "region": "us-east-1"
        },
        {
            "tableName": "Logs-main",
            "region": "us-east-1"
        },
        {
            "tableName": "Plans-main",
            "region": "us-east-1"
        },
        {
            "tableName": "Products-main",
            "region": "us-east-1"
        },
        {
            "tableName": "Properties-main",
            "region": "us-east-1"
        },
        {
            "tableName": "QueuedCalls-main",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
