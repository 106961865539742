/**
 * The function `filterArrays` takes in two arrays and filters out objects from the second array that
 * have a matching `productId` in the first array, returning two arrays - one with the matching objects
 * and one with the non-matching objects.
 * @param {Array<String>} clientProducts - The first array containing product IDs.
 * @param {Array<Object>} productsAvailable - The `productsAvailable` parameter is an array of objects.
 * @return {Array<Array<Object>>} The function `filterArrays` returns an array containing two arrays: `valoresEnArr1` and
 * `arrMissing`.
 */
function filterArrays(clientProducts, productsAvailable) {
  const arrOwn = [];
  const arrMissing = [];

  productsAvailable.forEach((objeto) => {
    if (objeto.productId && clientProducts.includes(objeto.productId)) {
      arrOwn.push(objeto);
    } else {
      arrMissing.push(objeto);
    }
  });

  return [arrOwn, arrMissing];
}

export default filterArrays;
