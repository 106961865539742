import React, { useState } from 'react';

import LogoApp from '../../Components/Logo';
import { Auth } from 'aws-amplify';
import { useParams } from 'react-router-dom';
import { useForm } from 'antd/lib/form/Form';
import { message, Button, Form, Input, Divider } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

function ChangePassword() {
  let { email } = useParams();
  const [form] = useForm();
  const navigate = useNavigate();
  const [loading] = useState(false);

  async function sendConfirmSignUp(values) {
    Auth.forgotPasswordSubmit(
      values.username,
      values.verificationCode,
      values.password
    )
      .then((data) => {
        message.success('Contraseña cambiada correctamente');
        navigate('/auth/login');
      })
      .catch((err) => {
        message.error('Ocurrión un error cambiando la contraseña');
        console.error(err);
      });
  }
  async function sendCode() {
    form
      .validateFields(['username'])
      .then(async (values) => {
        Auth.forgotPassword(values.username)
          .then((data) => {
            message.success('Se ha reenviado tu código de verificación');
          })
          .catch((err) => {
            message.error('Ocurrión un error enviandote el código');
            console.error(err);
          });
      })
      .catch((err) => {
        message.error('Por favor ingresa tu correo');
        console.error(err);
      });
  }

  return (
    <React.Fragment>
      <div
        // @ts-ignore
        align="middle"
      >
        <LogoApp width={'20%'} color="black" />
        <p>Ingresa el código de verificación enviado a tu correo electrónico</p>
        <h1 style={{ marginTop: '-16px' }}>Confirma tu cuenta</h1>
      </div>
      <Form
        form={form}
        name="basic"
        layout="vertical"
        initialValues={{
          username: email || '',
          verificationCode: '',
        }}
        onFinish={sendConfirmSignUp}
      >
        <Form.Item
          label="Correo"
          name="username"
          rules={[
            {
              required: true,
              message: 'Por favor ingresa tu correo',
            },
          ]}
        >
          <Input size="large" type="email" placeholder="john.doe@mail.com" />
        </Form.Item>
        <Form.Item
          label="Código de verificación"
          name="verificationCode"
          rules={[
            {
              required: true,
              message: 'Por favor ingresa el código de verificación',
            },
          ]}
        >
          <Input size="large" placeholder="123456" />
        </Form.Item>
        <Form.Item
          label="Nueva contraseña"
          name="password"
          rules={[
            {
              required: true,
              message: 'Por favor ingresa tu contraseña',
            },
          ]}
        >
          <Input.Password size="large" placeholder="Contraseña" required />
        </Form.Item>
        <div className="form-footer">
          ¿No recibiste el código?{' '}
          <Link onClick={sendCode} to={''}>
            reenvíalo
          </Link>
        </div>
        <Divider />

        <Form.Item>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            block
            size="large"
          >
            Confirmar
          </Button>
        </Form.Item>
      </Form>
    </React.Fragment>
  );
}

export default ChangePassword;
