import React from 'react';
import { Button, Popconfirm, Space, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const ColumnsComponent = ({ handleDelete }) => [
  {
    title: 'Nombre de la Propiedad',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Valor de la Propiedad',
    dataIndex: 'value',
    key: 'value',
  },
  {
    title: 'Acciones',
    key: 'actions',
    render: (_, record) => (
      <Space size="middle">
        <Tooltip
          title={
            record.value === 'phone' || record.value === 'mobilephone'
              ? 'No se puede eliminar esta propiedad'
              : 'Eliminar propiedad'
          }
        >
          <Popconfirm
            title="¿Estás seguro de eliminar esta propiedad?"
            onConfirm={() => handleDelete(record)}
            okText="Sí"
            cancelText="No"
            disabled={
              record.value === 'phone' || record.value === 'mobilephone'
            }
          >
            <Button
              type="primary"
              danger
              disabled={
                record.value === 'phone' || record.value === 'mobilephone'
              }
              icon={<DeleteOutlined />}
              size="small"
            />
          </Popconfirm>
        </Tooltip>
      </Space>
    ),
  },
];

export default ColumnsComponent;
