import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { ClockCircleTwoTone } from '@ant-design/icons';
import utc from 'dayjs/plugin/utc';

dayjs.extend(duration);
dayjs.extend(utc);

const ElapsedTime = ({
  utcDate,
  session,
  status,
  showDate = true,
  showTime = true,
  clockTwoToneColor,
}) => {
  const [elapsedTime, setElapsedTime] = useState('');
  const [localDate, setLocalDate] = useState('');

  useEffect(() => {
    const updateElapsedTime = () => {
      const now = dayjs();
      const pastDate = dayjs.utc(utcDate).local().add(-1, 'seconds');
      const diff = now.diff(pastDate);
      const durationObj = dayjs.duration(diff);
      const hours = String(durationObj.hours()).padStart(2, '0');
      const minutes = String(durationObj.minutes()).padStart(2, '0');
      const seconds = String(durationObj.seconds()).padStart(2, '0');
      setElapsedTime(`${hours}:${minutes}:${seconds}`);
      setLocalDate(pastDate.format('DD/MM/YYYY HH:mm:ss'));
    };

    updateElapsedTime();
    const intervalId = setInterval(updateElapsedTime, 1000); // Update every second

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, [utcDate]);

  const elapsedTimeColor = checkColor({ utcDate, session, status })
    ? 'red'
    : 'black';

  return (
    <div>
      {showDate && <div>Última actualización: {localDate}</div>}
      {showTime && (
        <div style={{ color: elapsedTimeColor }}>
          <ClockCircleTwoTone twoToneColor={clockTwoToneColor} /> Tiempo
          transcurrido: {elapsedTime}
        </div>
      )}
    </div>
  );
};

function checkColor({ utcDate, session, status }) {
  if (status !== 'Available' || !session) {
    return (
      dayjs.duration(dayjs().diff(dayjs.utc(utcDate).local())).asMinutes() > 15
    );
  } else {
    return false;
  }
}

export default ElapsedTime;
