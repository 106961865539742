import React from 'react';
import PageHeader from '../../../Components/Header/PageHeader';
import { Card, Col, Row, Result } from 'antd';
import { Link } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import { SmileOutlined } from '@ant-design/icons';
import WhatsAPPbutton from '../../../Components/Button/whatsAppButton';

export default function RecepcionistaVirtual() {
  const { clientInfo } = useAuth();
  const Message = `¡Hola Jennifer!, porfa cuéntame más acerca de Nua Talker Recepcionista virtual`;
  return (
    <Card>
      <PageHeader
        title="Recepcionista virtual"
        head="Nua Talker Recepcionista rirtual"
      >
        <p>
          ¡Te presentamos nuestra recepcionista virtual, una solución
          inteligente diseñada para facilitar la experiencia de tus clientes
          para agilizar el proceso de conectar con la persona que desea en
          segundos. Te permite liberar el tiempo de tu equipo y enfocarlos en
          mejorar tus ventas. ¡No te pierdas esta oportunidad!
        </p>
      </PageHeader>

      {clientInfo.products.includes('NTRV') ? (
        <Row gutter={16}>
          <Col span={12}>
            <Link to="directorio">
              <Card title="Directorio telefónico" bordered={false} hoverable>
                Administra el directorio telefónico de tu empresa, distribuye
                los desbordes como mejor lo necesites y maneja el horario de
                atención desde esta sección
              </Card>
            </Link>
          </Col>
        </Row>
      ) : (
        <Result
          icon={<SmileOutlined color="grenn" />}
          title="¡Hola! Parece que aún no tienes acceso a este servicio. Si deseas adquirirlo y disfrutar de todas sus ventajas, no dudes en ponerte en contacto con uno de nuestros asesores. "
          extra={<WhatsAPPbutton Message={Message} />}
        />
      )}
    </Card>
  );
}
