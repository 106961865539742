import React, { useState } from 'react';
import LogoApp from '../../Components/Logo';

import { useParams } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { useForm } from 'antd/lib/form/Form';
import { message, Button, Form, Input, Divider } from 'antd';
import { Link } from 'react-router-dom';

function Confirm() {
  let { email } = useParams();

  const [form] = useForm();
  const { confirmSignUp, sendConfirmationCode } = useAuth();
  const [loading] = useState(false);

  async function sendConfirmSignUp(values) {
    try {
      await confirmSignUp(values);
      message.success('¡Te registraste con éxito! 😎');
    } catch (err) {
      message.error('¡Ocurrió un error! ' + err.message);
      console.error({ err });
    }
  }
  async function sendCode() {
    form
      .validateFields(['username'])
      .then(async (values) => {
        try {
          const username = values.username;
          await sendConfirmationCode(username);
          message.success(
            'Código enviado, revisa la bandeja de tu correo electrónico'
          );
        } catch (err) {
          message.error('¡Ocurrió un error! ' + err.message);
          console.error({ err });
        }
      })
      .catch((err) => {
        message.error('Por favor ingresa el correo que quieres verificar.');
        console.error(err);
      });
  }

  return (
    <React.Fragment>
      <div align="middle">
        <LogoApp width={'20%'} color="black" />
        <p>Ingresa el código de verificación enviado a tu correo electrónico</p>
        <h1 style={{ marginTop: '-16px' }}>Confirma tu cuenta</h1>
      </div>
      <Form
        form={form}
        name="basic"
        layout="vertical"
        initialValues={{
          username: email || '',
          verificationCode: '',
        }}
        onFinish={sendConfirmSignUp}
      >
        <Form.Item
          label="Correo"
          name="username"
          rules={[
            {
              required: true,
              message: 'Por favor ingresa tu correo',
            },
          ]}
        >
          <Input size="large" type="email" placeholder="john.doe@mail.com" />
        </Form.Item>
        <Form.Item
          label="Code"
          name="verificationCode"
          rules={[
            {
              required: true,
              message: 'Por favor ingresa el código de verificación',
            },
          ]}
        >
          <Input size="large" placeholder="123456" />
        </Form.Item>
        <div className="form-footer">
          ¿No recibiste el código?{' '}
          <Link onClick={sendCode} to={''}>
            reenvíalo
          </Link>
        </div>
        <Divider />

        <Form.Item>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            block
            size="large"
          >
            Confirmar
          </Button>
        </Form.Item>
      </Form>
    </React.Fragment>
  );
}

export default Confirm;
