import React, { useCallback, useEffect, useState } from 'react';
import { Table, Button, Space, Form, message, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ModalComponent from './Components/ModalComponent';
import ColumnsComponent from './Components/ColumnsComponent';
import PageHeader from '../../Components/Header/PageHeader';
import useApi from '../../hooks/useApi';
import { ReloadOutlined } from '@ant-design/icons';

const PropertiesValidator = () => {
  const { isLoading, callApi } = useApi();
  const [properties, setProperties] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [propertyValue, setPropertyValue] = useState('');

  const loadProperties = useCallback(async () => {
    try {
      const data = await callApi({
        apiName: 'nuatalker',
        path: '/ntpv/{hubSpotId}',
        method: 'get',
      });
      const properties = Object.entries(data?.ntpv)
        .map(([key, value], index) => ({
          key: index + 1,
          name: value,
          value: key,
        }))
        .sort((a, b) => {
          // sort alphabetic value
          if (a.value < b.value) return -1;
          if (a.value > b.value) return 1;
          return 0;
        });
      setProperties(properties ?? []);
    } catch (error) {
      message.error('Error al cargar las propiedades del contacto');
    }
  }, [callApi]);

  useEffect(() => {
    loadProperties();
  }, [loadProperties]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      const updatedValues = {
        name: values.name,
        value: propertyValue || values.value,
      };
      await callApi({
        apiName: 'nuatalker',
        path: '/ntpv/{hubSpotId}',
        method: 'put',
        body: updatedValues,
      });
      setProperties([
        ...properties,
        { key: properties.length + 1, ...updatedValues },
      ]);
      form.resetFields();
      setIsModalVisible(false);
      setPropertyValue('');
    } catch (info) {
      console.log('Validate Failed:', info);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setPropertyValue('');
  };

  const onValuesChange = (changedValues) => {
    if (changedValues.name) {
      const newValue = changedValues.name
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '') // Elimina tildes
        .replace(/\s+/g, '_') // Reemplaza espacios con _
        .toLowerCase(); // Convierte a minúsculas
      form.setFieldsValue({ value: newValue });
      setPropertyValue(newValue);
    }
  };

  const handleDelete = async (record) => {
    try {
      if (record.value === 'mobilephone' || record.value === 'phone') {
        message.error('No puedes eliminar esta propiedad');
        return;
      }
      await callApi({
        apiName: 'nuatalker',
        path: `/ntpv/{hubSpotId}`,
        method: 'del',
        body: {
          value: record.value,
        },
      });
      setProperties(properties.filter((item) => item.key !== record.key));
      message.success('Propiedad eliminada correctamente');
    } catch (error) {
      message.error('Error al eliminar la propiedad');
    }
  };

  return (
    <React.Fragment>
      <PageHeader
        title="Propiedades de contacto"
        head="Phone validator"
        hrefHead="/service/phone-validator"
      >
        <p>
          En esta sección encontraras las propiedades de contacto que serán
          verificadas en el action{' '}
          <a
            href="https://ecosystem.hubspot.com/es/marketplace/apps/marketing/marketing-automation/nua-talker-342274"
            target="_blank"
            rel="noreferrer"
          >
            Nua Talker Phone Validator
          </a>{' '}
          para que puedas administrarlas en tus workflows de HubSpot.
        </p>
      </PageHeader>
      <Space>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={showModal}
          key={'button-ntpv-create-property'}
        >
          Añadir Propiedad
        </Button>
      </Space>
      <div
        style={{
          float: 'right',
        }}
      >
        Refrescar listado de propiedades{' '}
        <Tooltip title="Refrescar">
          <Button onClick={() => loadProperties()} icon={<ReloadOutlined />} />
        </Tooltip>
      </div>

      <Table
        columns={ColumnsComponent({ handleDelete })}
        dataSource={properties}
        pagination={true}
        loading={isLoading}
        rowKey="value"
        scroll={{ y: 600, x: 600 }}
      />
      <ModalComponent
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        form={form}
        onValuesChange={onValuesChange}
        key={'modal-ntpv-create-property'}
      />
    </React.Fragment>
  );
};

export default PropertiesValidator;
