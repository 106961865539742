import React from 'react';
import PageHeader from '../../../Components/Header/PageHeader';
import { Card, Col, Row, Result } from 'antd';
import { Link } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import { SmileOutlined } from '@ant-design/icons';
import WhatsAPPbutton from '../../../Components/Button/whatsAppButton';

export default function PhoneValidator() {
  const { clientInfo } = useAuth();
  const Message = `¡Hola Jennifer!, porfa cuéntame más acerca de Nua Talker Phone Validator`;
  return (
    <Card>
      <PageHeader title="Phone Validator">
        <p>
          ¡Descubre nuestra innovadora función de control de números de teléfono
          en tus propiedades de llamadas de tu CRM! Con esta poderosa
          herramienta, podrás gestionar y optimizar de manera eficiente todos
          los números de teléfono, garantizando una atención ágil y oportuna a
          tus clientes.
        </p>
      </PageHeader>

      {clientInfo.products.includes('NTPV') ? (
        <Row gutter={16}>
          <Col span={12}>
            <Link to="properties">
              <Card
                title="Administra tus propiedades de números de teléfono"
                bordered={false}
                hoverable
              >
                ¡Descubre nuestra innovadora función de control de propidades de
                números de teléfono, podrás gestionar y optimizar de manera
                eficiente todas las propiedades de manera que no pierdas tiempo
                en verificar si el número que tienes almacenado en tu CRM sea
                válido
              </Card>
            </Link>
          </Col>
        </Row>
      ) : (
        <Result
          icon={<SmileOutlined color="green" />}
          title="¡Hola! Parece que aún no tienes acceso a este servicio. Si deseas adquirirlo y disfrutar de todas sus ventajas, no dudes en ponerte en contacto con uno de nuestros asesores. "
          extra={<WhatsAPPbutton Message={Message} />}
        />
      )}
    </Card>
  );
}
