import { useEffect, useState } from 'react';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { listAgents } from '../graphql/queries';
import { onUpdateAgents, onCreateAgents } from '../graphql/subscriptions';

const useAgents = () => {
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchAgents = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const hubSpotId = user.attributes['custom:hubspotid'];
      const agentData = await API.graphql(
        graphqlOperation(listAgents, {
          hubSpotId,
          limit: 1000,
        })
      );
      setAgents(agentData.data.listAgents.items);
    } catch (error) {
      console.error('Error fetching agents:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let updateAgentSubscription;
    let createAgentSubscription;

    const updateListAgents = async (newAgent, reload = false) => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const hubSpotId = user.attributes['custom:hubspotid'];
        if (newAgent.hubSpotId === hubSpotId) {
          if (reload) {
            setLoading(true);
            await fetchAgents();
            setLoading(false);
          } else {
            setAgents((prevAgents) =>
              prevAgents.map((agent) =>
                agent.queue_extension === newAgent.queue_extension
                  ? newAgent
                  : agent
              )
            );
          }
        }
      } catch (error) {
        console.error('Error updating agents:', error);
      }
    };

    const subscribeToUpdates = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const hubSpotId = user.attributes['custom:hubspotid'];

        // Subscribe to agent updates
        updateAgentSubscription = API.graphql(
          graphqlOperation(onUpdateAgents, { hubSpotId })
        ).subscribe({
          next: ({ value }) => {
            const updatedAgent = value.data.onUpdateAgents;
            updateListAgents(updatedAgent, true);
          },
          error: (error) => {
            console.warn('Error on update agent subscription:', error);
          },
        });

        // Subscribe to agent creation
        createAgentSubscription = API.graphql(
          graphqlOperation(onCreateAgents, { hubSpotId })
        ).subscribe({
          next: ({ value }) => {
            const newAgent = value.data.onCreateAgents;
            updateListAgents(newAgent, true);
          },
          error: (error) => {
            console.warn('Error on create agent subscription:', error);
          },
        });
      } catch (error) {
        console.error('Error setting up subscriptions:', error);
      }
    };

    fetchAgents(); // Llamada inicial para obtener los agentes al montar el componente
    subscribeToUpdates(); // Configurar suscripciones al montar

    return () => {
      if (updateAgentSubscription) updateAgentSubscription.unsubscribe();
      if (createAgentSubscription) createAgentSubscription.unsubscribe();
    };
  }, []);

  const handleRefresh = async () => {
    setLoading(true);
    await fetchAgents();
    setLoading(false);
  };

  return { loading, agents, handleRefresh };
};

export default useAgents;
