import React, { useState, useEffect } from 'react';
import { API, Auth } from 'aws-amplify';
import { Table, Tooltip, Button } from 'antd';
import { Columns } from './Components/Columns';
import PageHeader from '../../Components/Header/PageHeader';
import { ReloadOutlined } from '@ant-design/icons';
export default function Queues() {
  const [queues, setQueues] = useState(null);
  const [charging, setCharging] = useState(true);
  const [hubSpotId, setHubSpotId] = useState(null);
  const [, setClient] = useState(null);

  async function getClient() {
    if (hubSpotId) {
      const path = `/clients/${hubSpotId}`;
      const myAPI = 'nuatalker';
      API.get(myAPI, path)
        .then((response) => {
          setClient(response.clientItems);
        })
        .catch((error) => {
          console.error('Error:', JSON.stringify(error));
        });
    }
  }

  async function getQueues() {
    setCharging(true);
    if (hubSpotId) {
      const path = '/dialer/v1/queue/list';
      const myAPI = 'dialer';
      const queryStringParameters = {
        hubSpotId: hubSpotId,
      };
      API.get(myAPI, path, { queryStringParameters })
        .then((response) => {
          setCharging(false);
          setQueues(response.options);
        })
        .catch((error) => {
          console.error('Error:', JSON.stringify(error));
          setCharging(false);
        });
    }
  }

  useEffect(() => {
    async function getUserInfo() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const hubSpotId = user.attributes['custom:hubspotid'];
        setHubSpotId(hubSpotId);
      } catch (err) {
        console.error({ err });
      }
    }
    getUserInfo();
  }, []);

  useEffect(() => {
    getQueues();
    getClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hubSpotId]);

  return (
    <React.Fragment>
      <PageHeader
        title="Gestión de colas"
        head="Marcador"
        hrefHead="/service/marcador"
      >
        <p>
          En esta sección encontraras tu listado de colas, podrás ver el estado
          de las colas, las llamadas y algunas opciones para gestionar tus
          colas.
        </p>
      </PageHeader>
      <div
        style={{
          float: 'right',
        }}
      >
        Refrescar estado de colas{' '}
        <Tooltip title="Refrescar">
          <Button onClick={getQueues} icon={<ReloadOutlined />} />
        </Tooltip>
      </div>
      <Table
        loading={charging}
        columns={Columns(hubSpotId, queues)}
        dataSource={queues ? queues : []}
        scroll={{ y: 600, x: 600 }}
        key="queueNumber"
      />
    </React.Fragment>
  );
}
