import React from 'react';
import AppRouter from './routes/AppRouter';
import { ConfigProvider } from 'antd';

// ConfigProvider.config({
//   theme: {
//     primaryColor: "#045BA8",
//   },
// });

export default function App() {
  return (
    <ConfigProvider>
      <AppRouter />
    </ConfigProvider>
  );
}
