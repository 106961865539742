import React, { Fragment } from 'react';
import PageHeader from '../../Components/Header/PageHeader';
import { Button, Table, Tooltip } from 'antd';
import { Columns, MainColumns } from './Components/Columns';
import useAgents from '../../hooks/useAgents';
import useAuth from '../../hooks/useAuth';
import { ReloadOutlined } from '@ant-design/icons';

export default function Agents() {
  const { agents, loading, handleRefresh } = useAgents();
  const { clientInfo } = useAuth();

  const groupedData = agents.reduce((acc, agent) => {
    const queue = agent.queue_extension.split('#')[0];
    if (!acc[queue]) {
      acc[queue] = [];
    }
    acc[queue].push(agent);
    return acc;
  }, {});

  const expandedRowRender = (queue) => {
    return (
      <Table
        columns={Columns({ agents: groupedData[queue], clientInfo })}
        dataSource={groupedData[queue]}
        pagination={false}
        size="small"
        key={'queue_extension'}
        rowKey="queue_extension"
      />
    );
  };

  const mainData = Object.keys(groupedData).map((queue) => ({
    queue,
    agents: groupedData[queue],
  }));

  return (
    <Fragment>
      <PageHeader
        title="Agentes de marcador"
        head="Marcador"
        key={'marcador-agentes'}
        hrefHead="/service/marcador"
      >
        <p>
          En esta sección encontraras tu listado de tus agentes, podrás ver el
          estado del agente, la sesión que tiene en la cola y el tiempo que ha
          transcurrido desde la última actualización
        </p>
      </PageHeader>
      <div
        style={{
          float: 'right',
        }}
      >
        Refrescar estado de agentes{' '}
        <Tooltip title="Refrescar">
          <Button onClick={handleRefresh} icon={<ReloadOutlined />} />
        </Tooltip>
      </div>
      <Table
        key={'queue'}
        rowKey="queue"
        loading={loading}
        columns={MainColumns()}
        dataSource={mainData}
        expandable={{
          expandedRowRender: (record) => expandedRowRender(record.queue),
        }}
        scroll={{ y: 600, x: 600 }}
        size="middle"
        pagination={true}
      />
    </Fragment>
  );
}
