import React from 'react';
import PageHeader from '../../../Components/Header/PageHeader';
import { Card, Result } from 'antd';
import useAuth from '../../../hooks/useAuth';
import { SmileOutlined } from '@ant-design/icons';
import WhatsAPPbutton from '../../../Components/Button/whatsAppButton';
export default function ChatBot() {
  const { clientInfo } = useAuth();
  const Message = `¡Hola Jennifer!, porfa cuéntame más acerca de Nua Talker University`;
  return (
    <Card>
      <PageHeader title="Chat-Bot" head="Nua Talker University">
        <p>
          ¡Te presentamos nuestro Chatbot Universitario, una solución
          inteligente diseñada para facilitar tu experiencia y agilizar los
          procesos de admisiones en la universidad! Con solo un clic, nuestro
          Chatbot te brindará respuestas rápidas y precisas a todas tus
          consultas sobre programas académicos, requisitos de admisión, fechas
          importantes y más.
        </p>
      </PageHeader>

      {clientInfo.products.includes('NTU') ? (
        <Result
          icon={<SmileOutlined />}
          title="Estamos trabajando arduamente para entregarte un gran producto. Pronto podrás administrar tu servicio aquí."
          // extra={<Button type="primary">Next</Button>}
        />
      ) : (
        <Result
          icon={<SmileOutlined color="grenn" />}
          title="¡Hola! Parece que aún no tienes acceso a este servicio. Si deseas adquirirlo y disfrutar de todas sus ventajas, no dudes en ponerte en contacto con uno de nuestros asesores. "
          extra={<WhatsAPPbutton Message={Message} />}
        />
      )}
    </Card>
  );
}
