import React from 'react';
import image from '../../Images/LOGO_BLACK.png';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useSpring, animated } from 'react-spring';

export default function Loading() {
  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          height: '100vh',
        }}
      >
        <AnimatedImage />
        <div className="flex space-x-2 items-center">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 18 }} spin />} />
          <span className="text-sm animate-pulse text-gray-400 sr-only"> </span>
        </div>
      </div>
    </React.Fragment>
  );
}

const AnimatedImage = () => {
  const props = useSpring({
    from: { opacity: 0, transform: 'scale(0.5)' },
    to: { opacity: 1, transform: 'scale(1)' },
    config: { duration: 1500 }, // Animation duration in milliseconds
  });

  return (
    <animated.img
      src={image}
      alt="logo"
      className="w-12 animate-bounce mb-4"
      style={{
        height: '100px',
        ...props,
      }}
    />
  );
};
