import React from 'react';
import { Badge, Card, Tooltip } from 'antd';
import { FaStar, FaTag } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export default function Product({ status, name, description }) {
  // const { Meta } = Card;
  const Message = `¡Hola Jennifer!, porfa cuéntame más acerca de ${name}`;

  return (
    <Link to={name ? `/service/${name.replace(/\s+/g, '-')}` : null}>
      <Badge.Ribbon
        text={status ? <FaStar /> : <FaTag />}
        color={status ? '#408FEC' : 'red'}
      >
        {status ? (
          <Card
            hoverable
            title={name}
            style={{ height: '250px' }}
            styles={{ body: { height: '150px', overflowY: 'auto' } }}
          >
            {description}
          </Card>
        ) : (
          <Tooltip
            title={
              <a
                href={`https://wa.me/+573103235693?text=${Message}`}
                style={{ color: 'white' }}
                target="_blank"
                rel="noreferrer"
              >
                ¿Más información?
              </a>
            }
            color="red"
            placement="topRight"
          >
            <Card
              hoverable
              title={name}
              style={{ height: '250px' }} // Estableces el tamaño de la carta
              bodyStyle={{ height: '150px', overflowY: 'auto' }}
            >
              {description}
            </Card>
          </Tooltip>
        )}
      </Badge.Ribbon>
    </Link>
  );
}
