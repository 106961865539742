import React from 'react';
import { Form, Input, Collapse } from 'antd';

const { Panel } = Collapse;

const FormComponent = ({ form, onValuesChange, key }) => (
  <Form
    key={key}
    form={form}
    layout="vertical"
    name="property_form"
    onValuesChange={onValuesChange}
  >
    <Form.Item
      name="name"
      label="Nombre de la propiedad"
      rules={[
        {
          required: true,
          message: 'Por favor ingrese el nombre de la propiedad',
        },
      ]}
      style={{ marginBottom: 1 }}
    >
      <Input placeholder="Número de teléfono personal" />
    </Form.Item>
    <Collapse size="small" ghost bordered={false}>
      <Panel header="Ver valor de la propiedad" key="1">
        <Form.Item
          name="value"
          rules={[
            {
              required: true,
              message: 'Por favor ingrese el valor de la propiedad',
            },
          ]}
        >
          <Input placeholder="numero_de_telefono_personal" disabled />
        </Form.Item>
      </Panel>
    </Collapse>
  </Form>
);

export default FormComponent;
