import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import LogoApp from '../../Components/Logo';
import { message, Button, Form, Input, Divider } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { Link, useNavigate } from 'react-router-dom';

function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [form] = useForm();

  function sendConfirmationCode(values) {
    setLoading(true);
    console.error(values);
    Auth.forgotPassword(values.username)
      .then(() => {
        message.success('Se ha enviado un código de verificación a tu correo');
        setLoading(false);
        navigate('/auth/confirmnewpassword/' + values.username);
      })
      .catch((err) => {
        message.error(err.message);
        setLoading(false);
      });
  }

  return (
    <React.Fragment>
      <div
        // @ts-ignore
        align="middle"
      >
        <LogoApp width={'20%'} color="black" />
        <p>Cambio de contraseña</p>
        <h1 style={{ marginTop: '-16px' }}>Recibe un código de verificación</h1>
      </div>
      <Form
        form={form}
        name="basic"
        layout="vertical"
        initialValues={{
          hubSpotId: '',
          email: '',
          password: '',
        }}
        onFinish={sendConfirmationCode}
      >
        <Form.Item
          label="Correo"
          name="username"
          rules={[
            {
              required: true,
              message: 'Por favor ingresa tu correo',
            },
          ]}
        >
          <Input size="large" type="email" placeholder="john.doe@mail.com" />
        </Form.Item>
        <Divider />
        <Form.Item>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            block
            size="large"
          >
            Solicitar código
          </Button>
        </Form.Item>
      </Form>
      <div className="form-footer">
        ¿Recordaste tu contraseña? <Link to="/auth/login">ingresa aquí</Link>
      </div>
    </React.Fragment>
  );
}

export default ResetPassword;
