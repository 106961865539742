import React from 'react';
import LogoApp from '../../Components/Logo';
import { Link, useNavigate } from 'react-router-dom';
import { message, Button, Form, Input, Divider } from 'antd';
import useAuth from '../../hooks/useAuth';

const passwordRules = [
  {
    required: true,
    message: 'Por favor ingresa tu contraseña',
  },
  {
    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/,
    message:
      'La contraseña debe tener al menos 8 caracteres, una mayúscula, una minúscula, un número y un carácter especial',
  },
];
function Register() {
  const navigate = useNavigate();
  const { signUp } = useAuth();

  async function sendSignUp(values) {
    try {
      await signUp(values);
      message.success(
        'Se ha enviado un código a tu correo para verificar tu email'
      );
      navigate('/auth/confirm/' + values.username);
    } catch (err) {
      message.error('¡Ocurrió un error! ' + err.message);
      console.error({ err });
    }
  }

  return (
    <React.Fragment>
      <div
        // @ts-ignore
        align="middle"
      >
        <LogoApp width={'20%'} color="black" />
        <p>Te doy la bienvenida</p>
        <h1 style={{ marginTop: '-16px' }}>¡Registrate aquí!</h1>
      </div>
      <Form
        name="basic"
        layout="vertical"
        initialValues={{
          hubSpotId: '',
          username: '',
          password: '',
        }}
        onFinish={sendSignUp}
      >
        <Form.Item
          label="ID de tu plataforma de HubSpot"
          name="hubSpotId"
          rules={[
            {
              required: true,
              message: 'Por favor ingresa tu ID de HubSpot',
            },
          ]}
        >
          <Input size="large" type={'text'} placeholder="12345" />
        </Form.Item>
        <Form.Item
          label="Correo"
          name="username"
          rules={[
            {
              required: true,
              message: 'Por favor ingresa tu correo',
            },
          ]}
        >
          <Input
            size="large"
            type="email"
            placeholder="username@yourmail.com"
          />
        </Form.Item>
        <Form.Item
          label="Contraseña"
          name="password"
          rules={[...passwordRules]}
        >
          <Input.Password size="large" placeholder="******" />
        </Form.Item>
        <Form.Item
          label="Confirmar contraseña"
          name="confirmPassword"
          rules={[
            ...passwordRules,
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('Las contraseñas no coinciden')
                );
              },
            }),
          ]}
        >
          <Input.Password size="large" placeholder="******" />
        </Form.Item>

        <Divider />
        <Form.Item>
          <Button type="primary" htmlType="submit" block size="large">
            Enviar
          </Button>
        </Form.Item>
      </Form>
      <div className="form-footer">
        ¿Ya tienes una cuenta? <Link to="/auth/login">inicia sesión aquí</Link>
      </div>
    </React.Fragment>
  );
}

export default Register;
