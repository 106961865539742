import { Avatar, Dropdown, Space, Typography } from 'antd';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { Navigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
const { Text } = Typography;

export default function NameDropdown() {
  const { user, logoutUser } = useAuth();
  const UserNameMenu = () => {
    if (!user) return <Navigate to="/auth/login" replace />;
    return [
      {
        label: (
          <div onClick={logoutUser}>
            <LogoutOutlined /> Salir
          </div>
        ),
        key: '0',
      },
    ];
  };
  return (
    <div
      className="container header"
      style={{
        marginLeft: 'auto',
        width: 'auto',
        padding: '3px 15px 3px 15px',
      }}
    >
      <Space>
        <Dropdown
          menu={{ items: UserNameMenu() }}
          trigger={['click']}
          placement="bottom"
        >
          <Space>
            <Avatar icon={<UserOutlined />} />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Text
                style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  color: '#000000',
                }}
              >
                {user?.email.split('@')[0] || 'correo'}
              </Text>
              <Text
                style={{
                  fontSize: '14px',
                  fontWeight: 'normal', // Cambia esto según tus necesidades
                  color: '#000000',
                  marginTop: '0px', // Controla el espacio vertical aquí
                  marginBottom: '0px', // Y aquí
                }}
              >
                {user ? user['custom:hubspotid'] : ''}
              </Text>
            </div>
          </Space>
        </Dropdown>
      </Space>
    </div>
  );
}
