import React from 'react';
import { Avatar, Badge, Space, Tag, Tooltip } from 'antd';
import { PhoneTwoTone } from '@ant-design/icons';
import ElapsedTime from './ElapsedTime';
import NoCallState from './NoCallState';

const colorTags = {
  Available: 'green',
  Away: 'orange',
  'Out of office': 'red',
};

let translateStates = {
  Available: 'Disponible',
  Away: 'Ausente',
  'Out of office': 'No molestar',
};

export function Columns({ agents, clientInfo }) {
  const env = process.env.REACT_APP_BRANCH || 'dev';
  if (!agents) {
    return [
      {
        title: 'Cargando...',
        dataIndex: 'loading',
        key: 'loading',
      },
    ];
  }
  translateStates = {
    ...translateStates,
    ...clientInfo?.properties?.states,
  };
  const columns = [
    {
      title: 'Nombre del agente',
      dataIndex: 'name',
      key: 'name',
      width: 300,
      render: (text, record) => (
        <Space size={'small'}>
          <Badge
            color={colorTags[record.status]}
            dot
            style={{ objectFit: 'cover' }}
          >
            <Avatar shape="square" alt={record.status}>
              {text[0] ?? ''}
            </Avatar>
          </Badge>
          <div>
            <h3 className="text-base font-semibold" style={{ margin: 'auto' }}>
              {text}
            </h3>
            <span>
              <span className="text-xs">
                Extensión: <Tag>{record.queue_extension.split('#')[1]}</Tag>
              </span>
            </span>
          </div>
        </Space>
      ),
      filters: agents.map((item) => ({
        text: item.name,
        value: item.name,
      })),
      onFilter: (value, record) => record.name.indexOf(value) === 0,
    },
    {
      title: 'Estado del agente',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      render: (text, record) => (
        <Space size={'small'}>
          <Tooltip
            title={`${translateStates[text] ?? text} ${
              record.displayName && ' - ' + record.displayName
            }`}
          >
            <Tag color={colorTags[text] ?? 'default'}>
              {translateStates[text] ?? text}
              {record.displayName && ' - ' + record.displayName}
            </Tag>
          </Tooltip>
        </Space>
      ),
      filters: Object.keys(translateStates).map((key) => ({
        text: translateStates[key],
        value: key.includes('custom') ? translateStates[key] : key,
      })),
      onFilter: (value, record) => record.status.indexOf(value) === 0,
    },
    {
      title: 'Sesión en cola',
      dataIndex: 'session',
      key: 'session',
      width: 150,
      render: (text) => (
        <Space size={'small'}>
          <Tag color={text ? 'blue' : 'default'}>
            {text ? 'Sesión iniciada' : 'Sesión cerrada'}
          </Tag>
        </Space>
      ),
      filters: [
        {
          text: 'Sesión iniciada',
          value: 'true',
        },
        {
          text: 'Sesión cerrada',
          value: 'false',
        },
      ],
      onFilter: (value, record) => record.session === (value === 'true'),
    },
    {
      title: 'Tiempo de sesión',
      dataIndex: 'lastUpdate',
      key: 'lastUpdate',
      width: 250,
      render: (text, record) => (
        <Space size={'small'}>
          <ElapsedTime
            utcDate={text}
            status={record.status}
            session={record.session}
            showDate={true}
            showTime={true}
          />
        </Space>
      ),
    },
  ];
  env === 'dev' &&
    columns.push({
      title: 'Estado de la llamada',
      dataIndex: 'inCall',
      key: 'inCall',
      width: 300,
      render: (inCall, record) => (
        <Space direction="vertical">
          {inCall ? (
            <div
              style={{
                backgroundColor: '#f6ffed',
                padding: '8px',
                borderRadius: '4px',
                border: '1px solid #b7eb8f',
                width: '250px',
              }}
            >
              <Badge status="processing" text="En llamada" color="green" />
              <div>
                <ElapsedTime
                  utcDate={record.callAnswerAt}
                  session={record.session}
                  status={record.status}
                  showDate={false}
                  clockTwoToneColor={'#52c41a'}
                />
                <div>
                  <PhoneTwoTone
                    twoToneColor="#52c41a"
                    style={{ marginRight: '8px' }}
                  />
                  Con: {record.callWith}
                </div>
              </div>
            </div>
          ) : (
            <NoCallState
              utcDate={record.callEndAt}
              session={record.session}
              status={record.status}
            />
          )}
        </Space>
      ),
    });
  return columns;
}

export function MainColumns() {
  return [
    {
      title: 'Colas de login y logout',
      dataIndex: 'queue',
      key: 'queue',
      render: (text) => (
        <Space size={'small'}>
          <Badge color={'blue'} dot style={{ objectFit: 'cover' }}>
            <Avatar shape="square" alt={text}>
              {text[0]}
            </Avatar>
          </Badge>
          <div>
            <h3 className="text-base font-semibold" style={{ margin: 'auto' }}>
              Cola número: {text}
            </h3>
          </div>
        </Space>
      ),
    },
  ];
}
