import React from 'react';
import { Modal } from 'antd';
import FormComponent from './FormComponent';
import { ExclamationCircleTwoTone } from '@ant-design/icons';
const ModalComponent = ({
  isModalVisible,
  handleOk,
  handleCancel,
  form,
  onValuesChange,
  key,
}) => (
  <Modal
    key={key}
    title="Añadir Propiedad"
    open={isModalVisible}
    onOk={handleOk}
    onCancel={handleCancel}
    okText="Guardar"
    cancelText="Cancelar"
  >
    <FormComponent
      form={form}
      onValuesChange={onValuesChange}
      key="form-ntpv-create-property"
    />
    <small>
      <ExclamationCircleTwoTone /> También se creará una propiedad de
      verificación con el nombre que asignes, anteponiendo la palabra{' '}
      <b>Verified</b>, con valores <b style={{ color: 'green' }}>verdadero</b> o{' '}
      <b style={{ color: 'red' }}>falso</b>. Esta propiedad se actualizará
      cuando verifiques el teléfono de la propiedad que estás creando.
    </small>
    <br />
    <small>
      <ExclamationCircleTwoTone /> Si la propiedad que creas ya existe, solo se
      registrará en la acción, pero puede que tengas problemas con la
      verificación del número en esta propiedad.
    </small>
  </Modal>
);

export default ModalComponent;
