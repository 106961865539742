import React from 'react';
import PageHeader from '../../../Components/Header/PageHeader';
import { Card, Col, Row, Result } from 'antd';
import { Link } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import { SmileOutlined } from '@ant-design/icons';
import WhatsAPPbutton from '../../../Components/Button/whatsAppButton';
export default function Voice() {
  const env = process.env.REACT_APP_BRANCH || 'dev';
  const { clientInfo } = useAuth();
  const Message = `¡Hola Jennifer!, porfa cuéntame más acerca de Nua Talker HubSpot: Voice`;

  return (
    <Card>
      <PageHeader title="Voz" head="Nua Talker HubSpot: Voice">
        <p>
          Puedes desplegar una llamada con voz generada por sintetizador para
          que transmita un mensaje a tu público objetivo, una vez el usuario
          conteste, sonará el audio que escribiste en la caja de texto y una vez
          finalice, colgará la llamada.
        </p>
      </PageHeader>
      {clientInfo.products.includes('NTHSVOI') ? (
        env === 'dev' ? (
          <Row gutter={16}>
            <Col span={12}>
              <Link to="dashboard?type=voice">
                <Card title="Consumos" bordered={false} hoverable>
                  Mira el estado de los envíos de notificaciones por voz en
                  tiempo real, observa como tus campañas de SMS se van enviando
                  a tus clientes finales
                </Card>
              </Link>
            </Col>
            <Col span={12}>
              <Link to="historial">
                <Card title="Historial" bordered={false} hoverable>
                  Accede a un registro detallado de todos los envíos de
                  notificaciones por voz, organizándolos y gestionándolos de
                  manera efectiva.
                </Card>
              </Link>
            </Col>
          </Row>
        ) : (
          <Result
            icon={<SmileOutlined />}
            title="Estamos trabajando arduamente para entregarte un gran producto. Pronto podrás administrar tu servicio aquí."
          />
        )
      ) : (
        <Result
          icon={<SmileOutlined color="grenn" />}
          title="¡Hola! Parece que aún no tienes acceso a este servicio. Si deseas adquirirlo y disfrutar de todas sus ventajas, no dudes en ponerte en contacto con uno de nuestros asesores. "
          extra={<WhatsAPPbutton Message={Message} />}
        />
      )}
    </Card>
  );
}
