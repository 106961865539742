import { Layout, Space } from 'antd';
import NameDropdown from './NameDropdown';
import { Link } from 'react-router-dom';
import LogoApp from '../../Logo';

const { Header } = Layout;

export default function AppHeader({ className }) {
  // Añade className como prop
  return (
    <Header
      style={{
        background: 'white',
        borderBottom: '1px solid #e8e8e8',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 20px',
        height: '55px',
      }}
    >
      <Link to="/">
        <Space align="center" className="select-none">
          <LogoApp width={120} color="black" />
        </Space>
      </Link>
      <NameDropdown />
    </Header>
  );
}
