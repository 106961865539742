import React from 'react';
import PageHeader from '../../../Components/Header/PageHeader';
import { Card, Col, Row, Result } from 'antd';
import { Link } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import { SmileOutlined } from '@ant-design/icons';
import WhatsAPPbutton from '../../../Components/Button/whatsAppButton';

export default function Marcador() {
  const { clientInfo } = useAuth();
  const Message = `¡Hola Jennifer!, porfa cuéntame más acerca de Nua Talker Agent Manager`;
  return (
    <Card>
      <PageHeader title="Marcador">
        <p>
          ¡Descubre nuestra innovadora función de control de colas de llamadas
          en tu marcador! Con esta poderosa herramienta, podrás gestionar y
          optimizar de manera eficiente todas las llamadas entrantes,
          garantizando una atención ágil y oportuna a tus clientes.
        </p>
      </PageHeader>

      {clientInfo.products.includes('NTAM') ? (
        <Row gutter={16}>
          <Col span={12}>
            <Link to="colas">
              <Card title="Gestión de colas" bordered={false} hoverable>
                ¡Descubre nuestra innovadora función de control de colas de
                llamadas en tu marcador! Con esta poderosa herramienta, podrás
                gestionar y optimizar de manera eficiente todas las llamadas
                entrantes, garantizando una atención ágil y oportuna a tus
                clientes.
              </Card>
            </Link>
          </Col>
          <Col span={12}>
            <Link to="agentes">
              <Card
                title="Seguimiento de sesión de agentes"
                bordered={false}
                hoverable
              >
                ¡Descubre nuestra innovadora función de gestión de sesiones de
                tus agentes de contact center! Con esta poderosa herramienta,
                podrás gestionar y tomar acción de manera eficiente el trabajo
                de tus agentes, garantizando una atención ágil y oportuna a tus
                clientes.
              </Card>
            </Link>
          </Col>
        </Row>
      ) : (
        <Result
          icon={<SmileOutlined color="green" />}
          title="¡Hola! Parece que aún no tienes acceso a este servicio. Si deseas adquirirlo y disfrutar de todas sus ventajas, no dudes en ponerte en contacto con uno de nuestros asesores. "
          extra={<WhatsAPPbutton Message={Message} />}
        />
      )}
    </Card>
  );
}
