import React, { Fragment } from 'react';
import './Styles/Logo.css';
import { Image } from 'antd';
import logo from '../../Images/LOGO.png';
import logoBlack from '../../Images/LOGO_BLACK.png';

function LogoApp({ width, color = 'white' }) {
  return (
    <Fragment>
      <Image
        className="text-center"
        src={color === 'white' ? logo : logoBlack}
        preview={false}
        alt="Logo App"
        width={width || '50%'}
        style={{
          marginBottom: '4px',
        }}
      />
    </Fragment>
  );
}

export default LogoApp;
