import React from 'react';
import { Button } from 'antd';
import { WhatsAppOutlined } from '@ant-design/icons';

export default function WhatsAPPbutton({ Message }) {
  return (
    <a
      href={`https://wa.me/+573103235693?text=${Message}`}
      style={{ color: 'white' }}
      target="_blank"
      rel="noreferrer"
    >
      <Button type="primary" style={{ backgroundColor: 'green' }}>
        <WhatsAppOutlined /> <span>Contactanos</span>
      </Button>
    </a>
  );
}
