import React from 'react';
import PageHeader from '../../../Components/Header/PageHeader';
import { Card, Col, Row, Result } from 'antd';
import { Link } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import { SmileOutlined } from '@ant-design/icons';
import WhatsAPPbutton from '../../../Components/Button/whatsAppButton';

export default function Agent() {
  const { clientInfo } = useAuth();
  const Message = `¡Hola Jennifer!, porfa cuéntame más acerca de Nua Talker Scheduler`;

  return (
    <Card>
      <PageHeader title="Agendador de citas" head="Nua Talker Scheduler">
        <p>
          ¡Presentamos nuestro innovador producto: el Agendador de Citas
          Inteligente! Este asombroso sistema simplifica por completo la
          programación de tus citas. Con solo una llamada, nuestro equipo
          altamente capacitado recolectará todos los datos necesarios y los
          registrará en nuestro avanzado calendario.
        </p>
      </PageHeader>

      {clientInfo.products.includes('NTS') ? (
        <Row gutter={16}>
          <Col span={12}>
            <Link to="consumos">
              <Card title="Consumos" bordered={false} hoverable>
                Accede a un registro detallado de todos tus marcadores
                guardados, organizándolos y gestionándolos de manera efectiva.
                Encuentra fácilmente tus páginas web favoritas y evita enlaces
                rotos. ¡Mejora tu experiencia de navegación con esta práctica
                herramienta
              </Card>
            </Link>
          </Col>
          <Col span={12}>
            <Link to="historial">
              <Card title="Historial" bordered={false} hoverable>
                Accede a un registro detallado de todos tus marcadores
                guardados, organizándolos y gestionándolos de manera efectiva.
                Encuentra fácilmente tus páginas web favoritas y evita enlaces
                rotos. ¡Mejora tu experiencia de navegación con esta práctica
                herramienta
              </Card>
            </Link>
          </Col>
        </Row>
      ) : (
        <Result
          icon={<SmileOutlined color="grenn" />}
          title="¡Hola! Parece que aún no tienes acceso a este servicio. Si deseas adquirirlo y disfrutar de todas sus ventajas, no dudes en ponerte en contacto con uno de nuestros asesores. "
          extra={<WhatsAPPbutton Message={Message} />}
        />
      )}
    </Card>
  );
}
