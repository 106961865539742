import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';

// Providers
import PublicLayout from '../Components/layouts/PublicLayout';
import PrivateLayout from '../Components/layouts/PrivateLayout';
import AuthProvider from '../context/AuthProvider';
// Pages
import Login from '../pages/Login';
import Register from '../pages/Register';
import Confirm from '../pages/Confirm';
import ChangePassword from '../pages/ChangePassword';
import Home from '../pages/Home';
// import Log from "../pages/Usage"; // TODO: PENDING for now
import ResetPassword from '../pages/ResetPassword';
import Queues from '../pages/Queues';
import History from '../pages/History';

// service pages
import Portafolio from '../pages/productos/portafolio/Index';
import Integracion from '../pages/productos/Integraciones';
import ChatBot from '../pages/productos/chatBot';
import Voice from '../pages/productos/Voz';
import SMS from '../pages/productos/sms';
import Marcador from '../pages/productos/marcador';
import Agent from '../pages/productos/Scheduler';
import Agents from '../pages/Agents';
import PhoneValidator from '../pages/productos/PhoneValidator';
import PropertiesValidator from '../pages/PropertiesValidator';
import RecepcionistaVirtual from '../pages/productos/recepcionistaVirtual/Index';

export default function AppRouter() {
  const env = process.env.REACT_APP_BRANCH || 'dev';

  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/auth" element={<PublicLayout />}>
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route path="resetpassword" element={<ResetPassword />} />
            <Route
              path="confirmnewpassword/:email"
              element={<ChangePassword />}
            />
            <Route path="confirm/:email" element={<Confirm />} />
            <Route path="*" element={<Navigate to="/auth/login" />} />
          </Route>
          <Route path="/" element={<PrivateLayout />}>
            <Route index element={<Navigate to="/home" replace />} />
            <Route path="home" element={<Home />} />
            {env === 'dev' ? (
              <Route
                path="/service/enviar-sms/reportes"
                element={<History />}
              />
            ) : (
              <Route path="/service/enviar-sms/reportes" element={<SMS />} />
            )}
            {env === 'dev' ? (
              // <Route path='/service/enviar-sms/consumos' element={<Log />} />
              <Route path="/service/enviar-sms/consumos" element={<SMS />} />
            ) : (
              <Route path="/service/enviar-sms/consumos" element={<SMS />} />
            )}
            <Route path="service/portafolio" element={<Portafolio />} />
            <Route path="service/integraciones" element={<Integracion />} />
            <Route path="service/chat-bot" element={<ChatBot />} />
            <Route path="service/marcador/colas" element={<Queues />} />
            <Route path="service/marcador/agentes" element={<Agents />} />
            <Route path="service/voz" element={<Voice />} />
            <Route path="service/agendador" element={<Agent />} />
            <Route path="service/marcador" element={<Marcador />} />
            <Route path="service/enviar-sms" element={<SMS />} />
            <Route path="service/agendador" element={<Agent />} />
            <Route
              path="service/recepcionista"
              element={<RecepcionistaVirtual />}
            />
            <Route
              path="service/phone-validator"
              element={<PhoneValidator />}
            />
            <Route
              path="service/phone-validator/properties"
              element={<PropertiesValidator />}
            />

            <Route path="*" element={<Navigate to="/home" replace />} />
          </Route>
        </Routes>
      </AuthProvider>
    </Router>
  );
}
