import React, { Fragment } from 'react';
import './Styles/style.css';
import { Divider, List, Skeleton } from 'antd';
import Product from './Components/Product';
import { FaRegStar } from 'react-icons/fa';

export default function Home() {
  const productsClient = JSON.parse(sessionStorage.getItem('productsClient'));
  const productsMissing = JSON.parse(sessionStorage.getItem('productsMissing'));
  const client = JSON.parse(sessionStorage.getItem('user'));

  return (
    <Fragment>
      {productsClient ? (
        <div>
          {productsClient.length !== 0 && (
            <div>
              <h2>Mis productos</h2>
              <Divider />
              <List
                grid={{
                  gutter: 16,
                  column: 4,
                }}
                dataSource={productsClient}
                renderItem={(item) => (
                  <List.Item>
                    <Product
                      status={
                        client ? client.products.includes(item.productId) : []
                      }
                      name={item.comercialName}
                      description={item.description}
                    />
                  </List.Item>
                )}
              />
            </div>
          )}
          <h2>Productos disponibles</h2>
          <Divider />
          <List
            grid={{
              gutter: 16,
              column: 4,
            }}
            dataSource={productsMissing ?? []}
            renderItem={(item) => (
              <List.Item>
                <Product
                  name={item.comercialName}
                  description={item.description}
                />
              </List.Item>
            )}
          />
        </div>
      ) : (
        <div>
          <List
            grid={{
              gutter: 16,
              column: 4,
            }}
            dataSource={[0, 1, 2, 3]}
            renderItem={() => (
              <List.Item>
                <Skeleton.Node
                  active={true}
                  style={{ width: '250px', minWidth: '200px' }}
                >
                  <FaRegStar style={{ width: '120px' }} />
                </Skeleton.Node>
              </List.Item>
            )}
          />
          <Divider />
          <List
            grid={{
              gutter: 16,
              column: 4,
            }}
            dataSource={[0, 1, 2, 3]}
            renderItem={() => (
              <List.Item>
                <Skeleton.Node
                  active={true}
                  style={{ width: '250px', minWidth: '200px' }}
                >
                  <FaRegStar style={{ width: '120px' }} />
                </Skeleton.Node>
              </List.Item>
            )}
          />
        </div>
      )}
    </Fragment>
  );
}
