import React from 'react';
import * as FaIcons from 'react-icons/fa';
import './Styles/RoundedButton.css';

const RoundedButton = ({ iconName, onClick, disabled }) => {
  const Icon = FaIcons[iconName];
  return (
    <button className="RoundedButton" onClick={onClick ? onClick:()=>(console.log(""))} disabled={disabled ? disabled : false}>
      <Icon className="icono" />
    </button>
  );
};

export default RoundedButton;