import React, { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @ts-ignore
import { Auth } from 'aws-amplify';
import Loading from '../Components/layouts/Loading';
import { message } from 'antd';
import useApi from '../hooks/useApi';
// @ts-ignore

/**
 * Authentication Context object.
 * @typedef {Object} AuthContextType
 * @property {function} loginUser - Function to log in a user.
 * @property {function} logoutUser - Function to log out a user.
 * @property {function} signUp - Function to sign up a new user.
 * @property {function} confirmSignUp - Function to confirm a user's signup.
 * @property {function} sendConfirmationCode - Function to resend confirmation code.
 * @property {Object} user - Current authenticated user details.
 * @property {Object} clientInfo - Client information fetched based on HubSpot ID.
 * @property {Array} servicios - List of services/products available to the user.
 * @property {string} sessionToken - Session token stored in local storage.
 */
export const AuthContext = createContext();

/**
 * Authentication Provider Component.
 * @component
 * @example <caption>Usage example:</caption>
 * <AuthProvider>
 *   <App />
 * </AuthProvider>
 */
export default function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const { callApi } = useApi();
  const [servicios, setServicios] = useState([]);
  const [clientInfo, setClienteInfo] = useState({ products: [] });
  const navigate = useNavigate();
  const [isCheckToken, setIsCheckToken] = useState(true);

  /**
   * Checks if the user's session token is valid and updates the user state accordingly.
   */
  useEffect(() => {
    if (!localStorage.getItem('sessionToken')) setIsCheckToken(false);

    checkToken();
    //eslint-disable-next-line
  }, []);

  /**
   * Validates the user's session token and fetches user and client information.
   */
  const checkToken = async () => {
    try {
      const current = await Auth.currentSession();
      const user = await Auth.currentAuthenticatedUser();
      localStorage.setItem(
        'sessionToken',
        current.getAccessToken().getJwtToken()
      );
      setUser(user.attributes);
      await Promise.allSettled([
        getClient(user.attributes['custom:hubspotid']),
        getProducts(),
      ]);
    } catch (error) {
      console.error('Check token error', error);
      goToLogin();
    } finally {
      setIsCheckToken(false);
    }
  };

  /**
   * Logs in a user with the provided credentials.
   * @param {Object} values - Object containing username and password.
   * @returns {Promise<void>} Resolves when the login process is complete.
   */
  const loginUser = async (values) => {
    const login = await Auth.signIn(values.username, values.password);
    const token = login.signInUserSession.accessToken.jwtToken;
    localStorage.setItem('sessionToken', token);
    setUser(login.attributes);
    return user;
  };

  /**
   * Logs out the current user and clears the session token.
   */
  const logoutUser = async () => {
    await Auth.signOut();
    goToLogin();
  };

  /**
   * Redirects the user to the login page.
   */
  const goToLogin = () => {
    localStorage.removeItem('sessionToken');
    setUser(null);
    navigate('/auth/login');
  };

  /**
   * Fetches product information for the authenticated user.
   */
  const getProducts = async () => {
    const path = `/products/v1`;
    const myAPI = 'nuatalker';
    try {
      const response = await callApi({
        apiName: myAPI,
        path,
        method: 'GET',
      });
      setServicios(response.products);
    } catch (error) {
      console.error('Error obteniendo productos:', JSON.stringify(error));
      throw error;
    }
  };

  /**
   * Fetches client information based on the HubSpot ID.
   * @param {string} hubSpotId - The HubSpot ID of the client.
   */
  const getClient = async (hubSpotId) => {
    const path = `/clients/${hubSpotId}`;
    const myAPI = 'nuatalker';
    try {
      const response = await callApi({
        apiName: myAPI,
        path,
        method: 'GET',
      });
      if (!response.clientItems) {
        message.error(
          'ID de HubSpot no encontrado, por favor verifique el número.'
        );
        logoutUser();
      } else {
        setClienteInfo(response.clientItems);
      }
    } catch (error) {
      console.error('Error obteniendo cliente:', JSON.stringify(error));
      throw error;
    }
  };

  /**
   * Signs up a new user with the provided credentials.
   * @param {Object} values - Object containing username, password, and HubSpot ID.
   */
  const signUp = async (values) => {
    try {
      await Auth.signUp({
        username: values.username,
        password: values.password,
        attributes: {
          'custom:hubspotid': values['hubSpotId'],
        },
      });
    } catch (err) {
      console.error(err);
      let errorMessage;
      switch (err.code) {
        case 'NetworkError':
          errorMessage = 'Error en la red.';
          break;
        case 'InvalidParameterException':
          errorMessage = 'Uno o más parámetros son incorrectos.';
          break;
        case 'LimitExceededException':
          errorMessage =
            'Límite re registros alcanzado, por favor intentalo más tarde.';
          break;
        case 'AliasExistsException':
          errorMessage =
            'El usuario ya se encuentra registrado en la plataforma.';
          break;
        case 'InvalidPasswordException':
          errorMessage = 'La contraseña no cumple con la política requerida.';
          break;
        default:
          console.error({ err });
          errorMessage = 'Error inesperado. Por favor intentalo más tarde';
      }
      throw new Error(errorMessage);
    }
  };

  /**
   * Confirms a user's signup using the provided verification code.
   * @param {Object} values - Object containing username and verification code.
   */
  async function confirmSignUp(values) {
    try {
      await Auth.confirmSignUp(values.username, values.verificationCode);
      navigate('/auth/login');
    } catch (err) {
      console.error(err);
      let errorMessage;
      switch (err.code) {
        case 'NetworkError':
          errorMessage = 'Error en la red.';
          break;
        case 'InvalidParameterException':
          errorMessage = 'Uno o más parámetros son incorrectos.';
          break;
        case 'CodeMismatchException':
          errorMessage = 'Código de verificación incorrecto.';
          break;
        case 'ExpiredCodeException':
          errorMessage = 'Código de verificación expirado.';
          break;
        default:
          console.error({ err });
          errorMessage = 'Error inesperado. Por favor intentalo más tarde';
      }
      throw new Error(errorMessage);
    }
  }

  /**
   * Resends the confirmation code to the user's email or phone number.
   * @param {string} value - The username (email or phone number) to resend the confirmation code to.
   */
  async function sendConfirmationCode(value) {
    try {
      await Auth.resendSignUp(value);
    } catch (err) {
      console.error(err);
      let errorMessage;
      switch (err.code) {
        case 'NetworkError':
          errorMessage = 'Error en la red.';
          break;
        case 'InvalidParameterException':
          errorMessage = 'Uno o más parámetros son incorrectos.';
          break;
        case 'LimitExceededException':
          errorMessage =
            'Límite re registros alcanzado, por favor intentalo más tarde.';
          break;
        default:
          console.error({ err });
          errorMessage = 'Error inesperado. Por favor intentalo más tarde';
      }
      throw new Error(errorMessage);
    }
  }

  /**
   * Returns the context value to be consumed by child components.
   * @type {AuthContextType}
   */
  const contextValue = {
    loginUser,
    logoutUser,
    signUp,
    confirmSignUp,
    sendConfirmationCode,
    user,
    clientInfo,
    servicios,
    sessionToken: localStorage.getItem('sessionToken'),
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {isCheckToken ? <Loading /> : children}
    </AuthContext.Provider>
  );
}
