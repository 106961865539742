import { useState, useCallback } from 'react';
import { message } from 'antd';
import { Auth, API } from 'aws-amplify';

const useApi = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const callApi = useCallback(
    async ({ apiName, path, method = 'get', headers = {}, body = {} }) => {
      setIsLoading(true);
      setError(null);
      try {
        const user = await Auth.currentAuthenticatedUser();
        const hubSpotId = user.attributes['custom:hubspotid'];
        const fullPath = path.replace('{hubSpotId}', hubSpotId);
        const options = { headers, body };

        const response = await API[method.toLowerCase()](
          apiName,
          fullPath,
          options
        );

        setIsLoading(false);
        return response;
      } catch (err) {
        setIsLoading(false);
        setError(err);
        message.error(
          'Error al realizar la operación, por favor intentalo de nuevo más tarde'
        );
        console.error('API error message', err.response.data);
        throw err;
      }
    },
    []
  );

  return { isLoading, error, callApi };
};

export default useApi;
