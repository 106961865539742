import { API } from 'aws-amplify';
const myAPI = 'nuatalker';
async function matchProducts(user) {
  const [clientResponse, products] = await Promise.all([
    API.get(myAPI, `/clients/${user['custom:hubspotid']}`),
    API.get(myAPI, `/products/v1`),
  ]);
  return [clientResponse, products];
}

export default matchProducts;
